// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-galerie-js": () => import("./../../../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalender-js": () => import("./../../../src/pages/kalender.js" /* webpackChunkName: "component---src-pages-kalender-js" */),
  "component---src-pages-karte-js": () => import("./../../../src/pages/karte.js" /* webpackChunkName: "component---src-pages-karte-js" */),
  "component---src-templates-aktuelles-js": () => import("./../../../src/templates/aktuelles.js" /* webpackChunkName: "component---src-templates-aktuelles-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/staticPage.js" /* webpackChunkName: "component---src-templates-static-page-js" */)
}

